.ct-error-container {
  width: 100%;
  position: fixed;
  background: white;
  z-index: 99;
  bottom: 0;
  box-shadow: 0px -5px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  height: 230px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.ct-error {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 250px;
  width: 100%;
  text-align: center;
}

.ct-loader {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.864);
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  width: 100%;
  height: 100%;
  flex-direction: column;
}

.ct-loader.ct-open {
  display: flex;
}

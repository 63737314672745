@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400&display=swap);
.ct-error-container {
  width: 100%;
  position: fixed;
  background: white;
  z-index: 99;
  bottom: 0;
  box-shadow: 0px -5px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  height: 230px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.ct-error {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 250px;
  width: 100%;
  text-align: center;
}

.ct-top-nav {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 60px;
  box-shadow: 0 0 1px 0 rgb(8 11 14 / 6%), 0 16px 16px -1px rgb(8 11 14 / 10%);
  z-index: 1000;
  background: white;
}

.ct-top-nav > .ct-max-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.ct-top-nav > .ct-max-container > .ct-title {
  padding: 0 20px 0 20px;
}

.ct-top-nav > .ct-max-container > .ct-title > h1 {
  font-size: 16px;
  font-weight: lighter;
  display: flex;
  align-items: center;
}

.ct-top-nav > .ct-max-container > .ct-title > h1 > span {
  margin-right: 10px;
}

.ct-top-nav > .ct-max-container > .ct-title > h1 > img {
  width: 60px;
  padding-right: 15px;
}

.ct-top-nav > .ct-max-container > .ct-links {
  display: flex;
  padding-right: 20px;
}

.ct-top-nav > .ct-max-container > .ct-links > .ct-data {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.ct-top-nav > .ct-max-container > .ct-links .ct-balance {
  font-size: 9px;
  font-weight: lighter;
  display: flex;
  align-items: center;
  text-align: right;
}

.ct-top-nav > .ct-max-container > .ct-links .ct-balance > a {
  font-size: 11px;
  margin-right: 7px;
  cursor: pointer;
  color: black;
}

.ct-top-nav > .ct-max-container > .ct-links .ct-address {
  text-overflow: ellipsis;
  width: 50px;
  white-space: nowrap;
  overflow: hidden;
  direction: rtl;
  font-size: 9px;
  font-weight: lighter;
  text-align: right;
}

.ct-top-nav > .ct-max-container > .ct-links > button {
  background: white;
  border: 0;
  border-bottom: 2px solid black;
  cursor: pointer;
}

.ct-top-nav > .ct-max-container > .ct-links .ct-actions {
  margin-left: 5px;
  display: flex;
  align-items: center;
}

.ct-top-nav > .ct-max-container > .ct-links .ct-actions > span {
  cursor: pointer;
  margin-left: 5px;
  font-size: 12ipx;
}

@media screen and (min-width: 500px) {
  .ct-top-nav > .ct-max-container > .ct-links .ct-balance {
    font-size: 12px;
  }

  .ct-top-nav > .ct-max-container > .ct-links .ct-balance > a {
    font-size: 12px;
  }

  .ct-top-nav > .ct-max-container > .ct-links .ct-address {
    font-size: 12px;
  }
}

.ct-left-nav {
  box-shadow: 0 0 1px 0 rgb(8 11 14 / 20%), 0 16px 16px -1px rgb(8 11 14 / 50%);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  max-width: 250px;
  left: -270px;
  background: white;
  z-index: 1000;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: all 0.2s ease;
}

.ct-left-nav.ct-open {
  left: 0;
}

.ct-left-nav > .ct-link-parent {
  padding: 0 10px;
  margin-bottom: 15px;
}

.ct-left-nav > .ct-link-parent > span {
  font-size: 16px;
  color: grey;
  text-transform: uppercase;
  font-weight: lighter;
}

.ct-left-nav > .ct-link-parent > .ct-parent-links {
  padding-left: 10px;
  margin-top: 5px;
  display: flex;
  flex-direction: column;
}

.ct-left-nav > .ct-link-parent > .ct-parent-links > a {
  color: black;
  text-decoration: none;
  position: relative;
  padding: 5px;
  font-weight: lighter;
}

.ct-left-nav > .ct-link-parent > .ct-parent-links > a::before {
  content: "";
  position: absolute;
  bottom: 0;
  background: black;
  height: 1px;
  left: 0;
  width: 50%;
  transition: all 0.2s ease;
}

.ct-left-nav > .ct-link-parent > .ct-parent-links > a:hover::before {
  width: 100%;
}

.ct-left-nav > a {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: black;
  transition: all 0.2s ease;
}

.ct-left-nav > a:hover {
  box-shadow: 0 0 1px 0 rgb(8 11 14 / 6%), 0 16px 16px -1px rgb(8 11 14 / 10%);
}


.ct-nft {
  width: 90%;
  max-width: 200px;
  margin: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.ct-nft.ct-small {
  margin: 7px;
  max-width: 150px;
}

.ct-nft.ct-clickeable {
  cursor: pointer;
}

.ct-nft.ct-invalid {
  background: rgb(255, 184, 184);
}

.ct-nft.ct-clicked {
  background: rgb(189, 247, 189);
}

.ct-nft:hover {
  box-shadow: 0 10px 20px rgba(43, 42, 42, 0.432),
    0 6px 6px rgba(34, 34, 34, 0.473);
}

.ct-nft > .ct-image {
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ct-nft.ct-small > .ct-image {
  height: 100px;
}

.ct-nft > .ct-image > img {
  object-fit: contain;
  object-position: center;
  max-width: 90%;
}

.ct-nft > .ct-data {
  padding: 15px;
  display: flex;
  flex-direction: column;
  font-size: 12px;
}

.ct-nft.ct-small > .ct-data {
  font-size: 10px;
}

.ct-nft > .ct-actions-container > .ct-actions {
  display: flex;
  flex-wrap: wrap;
}

.ct-nft > .ct-actions-container > .ct-actions > button {
  flex: 1 1;
  width: auto;
}

.ct-nft button {
  border-radius: 4px;
}

.ct-nft > .ct-actions-container .ct-action-message > .ct-message {
  font-size: 10px;
  padding: 5px;
}

.ct-nft > .ct-actions-container .ct-action-message > .ct-message > button {
  width: 90%;
  margin: 5px auto !important;
}

.ct-reward-data {
  flex: 1 1;
}

.ct-reward-data > .ct-rewards-info {
  display: flex;
  flex-direction: column;
  padding: 5px;
}

.ct-reward-data > .ct-rewards-info > small {
  font-size: 10px;
}

.ct-nft-modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: none;
  align-items: center;
  justify-content: center;
  background: rgba(38, 38, 38, 0.515);
}

.ct-nft-modal.ct-open {
  display: flex;
  z-index: 99;
}

.ct-nft-modal > .ct-modal {
  background: white;
  padding: 15px;
}

.ct-nft-modal > .ct-modal > h1 {
  width: 100%;
  font-size: 18px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
}

.ct-nft-modal > .ct-modal > h1 > span {
  cursor: pointer;
}

.ct-nft-modal .ct-nft {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  width: 100%;
  max-width: 300px;
  position: relative;
  background: white;
  padding: 25px;
}

.ct-nft-modal .ct-nft:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.ct-nft-modal .ct-nft-info > .ct-nft > .ct-image > img {
  object-fit: contain;
  width: 100%;
  height: 100%;
  object-position: center;
}

.ct-nft-modal .ct-nft > .ct-stats {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}

.ct-builder-actions {
  width: 90%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 15px;
  padding-top: 15px;
}

.ct-builder-actions > button,
.ct-builder-actions > a {
  background: black;
  border: 0;
  color: white;
  height: 30px;
  width: 100%;
  padding: 0 15px;
  font-weight: bold;
  cursor: pointer;
  margin: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-size: 12px;
  text-decoration: none;
}

.ct-builder-actions > button > span,
.ct-builder-actions > a > span {
  margin-right: 7px;
}

.ct-builder-actions > button:disabled {
  background: grey;
}

@media screen and (min-width: 450px) {
  .ct-builder-actions > button,
  .ct-builder-actions > a {
    width: auto;
  }
}

.ct-filters {
  background: rgba(49, 49, 49, 0.261);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  align-items: center;
  justify-content: center;
}

.ct-filters.ct-open {
  display: flex;
}

.ct-filters > .ct-modal {
  background: white;
  width: 100%;
  max-width: 300px;
  padding: 15px;
}

.ct-filters > .ct-modal > h1 {
  width: 100%;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.ct-filters > .ct-modal > h1 > span {
  cursor: pointer;
}

.ct-filters > .ct-modal > form > .ct-selector {
  display: flex;
  flex-direction: column;
}

.ct-filters > .ct-modal > form > .ct-selector > label {
  font-weight: bold;
  font-size: 15px;
}

.ct-filters > .ct-modal > form > .ct-selector > select {
  border: 0;
  border-bottom: 1px solid black;
  background: white;
  height: 30px;
}

.ct-filters > .ct-modal > form > .ct-selector > select,
.ct-filters > .ct-modal > form > .ct-selector > select:active,
.ct-filters > .ct-modal > form > .ct-selector > select:focus {
  outline: none;
}

.ct-builder-grid {
  width: 100%;
  display: flex;
  min-height: 200px;
  justify-content: center;
  align-items: center;
}

.ct-builder-grid > .ct-grid-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
}

.ct-builder-grid > .ct-grid-container > .ct-nft {
  width: 90%;
  max-width: 200px;
  margin: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.ct-builder-grid > .ct-grid-container > .ct-nft > .ct-image {
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ct-builder-grid > .ct-grid-container > .ct-nft > .ct-image > img {
  object-fit: contain;
  object-position: center;
  max-width: 90%;
}

.ct-builder-grid > .ct-grid-container > .ct-nft > .ct-data {
  padding: 15px;
  display: flex;
  flex-direction: column;
  font-size: 12px;
}

.ct-builder-grid > .ct-grid-container > .ct-pagination {
  flex: 0 0 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ct-builder-grid > .ct-grid-container > .ct-pagination > span {
  font-size: 18px;
  margin: 7px;
  padding: 5px;
  cursor: pointer;
}

.ct-builder-grid .ct-without-data a {
  text-decoration: none;
  font-size: 12px;
  max-width: 200px;
  display: flex;
  margin: 5px auto;
}

.ct-builder-grid .ct-without-data p {
  text-align: center;
  max-width: 90%;
  margin: 0 auto;
  display: block;
}

.ct-builder-grid .ct-without-data a > img {
  width: 20px;
  margin-left: 10px;
  display: block;
}

.ct-loader {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.864);
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  width: 100%;
  height: 100%;
  flex-direction: column;
}

.ct-loader.ct-open {
  display: flex;
}

.ct-footer {
  width: 100%;
  margin-top: 30px;
  padding-top: 20px;
  box-shadow: 0px -5px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
}

.ct-footer > .ct-big-info-container {
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
}

.ct-footer > .ct-big-info-container > .ct-big-info {
  margin: 15px;
  display: flex;
  flex-direction: column;
}

.ct-footer > .ct-big-info-container > .ct-big-info > h4 {
  width: 100%;
  margin-bottom: 15px;
  font-size: 14px;
}

.ct-footer > .ct-big-info-container > .ct-big-info > a > span {
  margin-left: 7px;
  font-size: 14px;
}

.ct-footer > .ct-big-info-container > .ct-big-info > a {
  color: black;
  text-decoration: none;
  font-size: 12px;
}

.ct-footer .ct-token-footer {
  font-size: 12px;
  text-align: center;
  flex: 0 0 100%;
  margin-top: 10px;
  margin-bottom: 50px;
}

.ct-footer .ct-token-footer a {
  text-decoration: none;
  color: black;
  font-size: 10px;
}

@media screen and (min-width: 730px) {
  .ct-footer > .ct-big-info-container {
    display: flex;
  }
}

.ct-relations-rewards {
  margin-top: 50px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.ct-relations-rewards > h1 small {
  font-size: 12px;
}

.ct-relations-rewards > h1 {
  display: flex;
  align-items: center;
}

.ct-relations-rewards > h1 > span {
  display: flex;
  flex-direction: column;
}

.ct-relations-rewards > button {
  max-width: 200px;
  margin-top: 20px;
}

.ct-select-employees {
  color: red;
  font-weight: bold;
  margin-top: 15px;
}

.ct-factory-selector-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.ct-factory-selector-container > h2,
.ct-employee-selector-container > h2 {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 10px;
}

.ct-factory-selector-container > small,
.ct-employee-selector-container > small {
  margin-bottom: 25px;
}

.ct-employee-selector-container > h2 > span {
  font-size: 15px;
  margin-left: 15px;
  cursor: pointer;
}

.ct-employee-selector-container > h2 > span > span {
  margin-left: 5px;
}

.ct-factory-selector-container .ct-factory-selector {
  padding: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.ct-factory-selector-container .ct-factory-selector > .ct-selected-factory {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ct-factory-selector-container
  .ct-factory-selector
  > .ct-selected-factory
  > div.ct-explanation {
  flex: 0 0 30%;
  max-width: 30%;
}

.ct-factory-selector-container
  .ct-factory-selector
  > .ct-selected-factory
  > div.ct-explanation
  > p {
  font-size: 13px;
}

.ct-factory-selector-container .ct-factory-selector > .ct-select-factory {
  height: 200px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.ct-factory-selector-container .ct-factory-selector > .ct-select-factory > h4 {
  max-width: 150px;
}

.ct-factory-selector-container .ct-factory-selector > .ct-selector-actions {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.ct-factory-selector-container
  .ct-factory-selector
  > .ct-selector-actions
  > div {
  cursor: pointer;
  margin: 0 10px;
  font-size: 12px;
}

.ct-factory-selector-container .ct-posible-factories {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 25px;
}

.ct-load-factories {
  font-size: 18px;
  font-weight: bold;
}

/* Employees */

.ct-employee-selector-container {
  text-align: center;
  display: flex;
  flex-direction: column;
}

.ct-employee-selector-container > .ct-employees {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.ct-game-modal {
  background: rgba(34, 34, 34, 0.705);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ct-game-modal > .ct-data-container {
  background: white;
  padding: 15px;
  width: 90%;
  max-width: 350px;
}

.ct-game-modal > .ct-data-container > h1 {
  font-size: 25px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.ct-game-modal > .ct-data-container > h1 > span {
  font-size: 15px;
  cursor: pointer;
}

.ct-game-modal > .ct-data-container > h3 {
  font-size: 15px;
}

.ct-game-modal > .ct-data-container > p {
  margin-top: 15px;
  font-size: 12px;
}

.ct-game-modal > .ct-data-container > p > a {
  font-weight: bold;
  color: black;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Roboto Condensed", sans-serif;
}

body {
  max-width: 100vw;
  max-height: 100vh;
}

.ct-app-container {
  width: 100%;
  position: relative;
  margin-top: 60px;
}

.ct-max-container {
  max-width: 1200px;
  margin: 0 auto;
}

/* -------------------------------------------------------------------------- */
/*                               ANCHOR Buttons                               */
/* -------------------------------------------------------------------------- */

.ct-main-button {
  transition: all 0.2s ease;
  background: black;
  border: 0;
  color: white;
  height: 30px;
  padding: 0 15px;
  font-weight: bold;
  cursor: pointer;
  margin: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 98%;
  border-radius: 4px;
}

.ct-reaload-button {
  background: yellow;
  color: black;
  font-weight: bold;
  border: 0;
  display: flex;
  height: 30px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.ct-main-button:disabled {
  background: #ef9a9a;
}

.ct-template-button {
  background: black;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  text-transform: uppercase;
  font-size: 12px;
  border: 0;
  color: white;
  height: 40px;
  cursor: pointer;
}

.ct-main-button:disabled:hover {
  background: #ef9a9a;
}

.ct-main-button.ct-active {
  background: #7e7e7e;
}

.ct-main-button:hover {
  background: #7e7e7e;
}

/* -------------------------------------------------------------------------- */
/*                               ANCHOR Margins                               */
/* -------------------------------------------------------------------------- */

.ct-mb-1 {
  margin-bottom: 1px;
}

.ct-mb-2 {
  margin-bottom: 2px;
}

.ct-mb-5 {
  margin-bottom: 5px;
}

.ct-pt-30 {
  padding-top: 30px;
}

/* -------------------------------------------------------------------------- */
/*                               ANCHOR Comming                               */
/* -------------------------------------------------------------------------- */

.ct-comming-soon {
  display: flex;
  width: 100%;
  height: calc(100vh - 60px);
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.ct-comming-soon > button {
  background: black;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  text-transform: uppercase;
  font-size: 12px;
  border: 0;
  color: white;
  height: 40px;
  cursor: pointer;
}

.ct-comming-soon > h4 {
  font-size: 35px;
  text-transform: uppercase;
}

/* -------------------------------------------------------------------------- */
/*                              ANCHOR Calculator                             */
/* -------------------------------------------------------------------------- */

.ct-calculator-container {
  padding-top: 15px;
}

.ct-calculator-container > h1 {
  text-align: center;
  margin-bottom: 15px;
  text-transform: uppercase;
  font-size: 18px;
}

div.ct-shadow {
  background: rgba(248, 248, 248, 0.514);
  z-index: 99;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 32px;
}

div.ct-shadow.ct-active.page {
  display: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-feature-settings: "smcp";
  font-variant: small-caps;
}

.ct-text-center {
  text-align: center;
}

.ct-text-light {
  font-weight: lighter;
}

.spinner {
  width: 40px;
  height: 40px;
  background-color: #333;
  margin: 100px auto;
  animation: sk-rotateplane 1.2s infinite ease-in-out;
}

@keyframes sk-rotateplane {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }
  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }
  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}

.ct-relations-game > h1 {
  text-align: center;
  padding-top: 55px;
}

.ct-relations-game > p {
  text-align: center;
}

.ct-relations-without-factories {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 45px;
}

.ct-relations-without-factories > a {
  font-weight: bold;
  color: black;
  font-size: 16px;
}


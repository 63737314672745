.ct-builder-actions {
  width: 90%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 15px;
  padding-top: 15px;
}

.ct-builder-actions > button,
.ct-builder-actions > a {
  background: black;
  border: 0;
  color: white;
  height: 30px;
  width: 100%;
  padding: 0 15px;
  font-weight: bold;
  cursor: pointer;
  margin: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-size: 12px;
  text-decoration: none;
}

.ct-builder-actions > button > span,
.ct-builder-actions > a > span {
  margin-right: 7px;
}

.ct-builder-actions > button:disabled {
  background: grey;
}

@media screen and (min-width: 450px) {
  .ct-builder-actions > button,
  .ct-builder-actions > a {
    width: auto;
  }
}

.ct-factory-selector-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.ct-factory-selector-container > h2,
.ct-employee-selector-container > h2 {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 10px;
}

.ct-factory-selector-container > small,
.ct-employee-selector-container > small {
  margin-bottom: 25px;
}

.ct-employee-selector-container > h2 > span {
  font-size: 15px;
  margin-left: 15px;
  cursor: pointer;
}

.ct-employee-selector-container > h2 > span > span {
  margin-left: 5px;
}

.ct-factory-selector-container .ct-factory-selector {
  padding: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.ct-factory-selector-container .ct-factory-selector > .ct-selected-factory {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ct-factory-selector-container
  .ct-factory-selector
  > .ct-selected-factory
  > div.ct-explanation {
  flex: 0 0 30%;
  max-width: 30%;
}

.ct-factory-selector-container
  .ct-factory-selector
  > .ct-selected-factory
  > div.ct-explanation
  > p {
  font-size: 13px;
}

.ct-factory-selector-container .ct-factory-selector > .ct-select-factory {
  height: 200px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.ct-factory-selector-container .ct-factory-selector > .ct-select-factory > h4 {
  max-width: 150px;
}

.ct-factory-selector-container .ct-factory-selector > .ct-selector-actions {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.ct-factory-selector-container
  .ct-factory-selector
  > .ct-selector-actions
  > div {
  cursor: pointer;
  margin: 0 10px;
  font-size: 12px;
}

.ct-factory-selector-container .ct-posible-factories {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 25px;
}

.ct-load-factories {
  font-size: 18px;
  font-weight: bold;
}

/* Employees */

.ct-employee-selector-container {
  text-align: center;
  display: flex;
  flex-direction: column;
}

.ct-employee-selector-container > .ct-employees {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

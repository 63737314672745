.ct-filters {
  background: rgba(49, 49, 49, 0.261);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  align-items: center;
  justify-content: center;
}

.ct-filters.ct-open {
  display: flex;
}

.ct-filters > .ct-modal {
  background: white;
  width: 100%;
  max-width: 300px;
  padding: 15px;
}

.ct-filters > .ct-modal > h1 {
  width: 100%;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.ct-filters > .ct-modal > h1 > span {
  cursor: pointer;
}

.ct-filters > .ct-modal > form > .ct-selector {
  display: flex;
  flex-direction: column;
}

.ct-filters > .ct-modal > form > .ct-selector > label {
  font-weight: bold;
  font-size: 15px;
}

.ct-filters > .ct-modal > form > .ct-selector > select {
  border: 0;
  border-bottom: 1px solid black;
  background: white;
  height: 30px;
}

.ct-filters > .ct-modal > form > .ct-selector > select,
.ct-filters > .ct-modal > form > .ct-selector > select:active,
.ct-filters > .ct-modal > form > .ct-selector > select:focus {
  outline: none;
}

.ct-nft-modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: none;
  align-items: center;
  justify-content: center;
  background: rgba(38, 38, 38, 0.515);
}

.ct-nft-modal.ct-open {
  display: flex;
  z-index: 99;
}

.ct-nft-modal > .ct-modal {
  background: white;
  padding: 15px;
}

.ct-nft-modal > .ct-modal > h1 {
  width: 100%;
  font-size: 18px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
}

.ct-nft-modal > .ct-modal > h1 > span {
  cursor: pointer;
}

.ct-nft-modal .ct-nft {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  width: 100%;
  max-width: 300px;
  position: relative;
  background: white;
  padding: 25px;
}

.ct-nft-modal .ct-nft:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.ct-nft-modal .ct-nft-info > .ct-nft > .ct-image > img {
  object-fit: contain;
  width: 100%;
  height: 100%;
  object-position: center;
}

.ct-nft-modal .ct-nft > .ct-stats {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}

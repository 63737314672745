.ct-builder-grid {
  width: 100%;
  display: flex;
  min-height: 200px;
  justify-content: center;
  align-items: center;
}

.ct-builder-grid > .ct-grid-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
}

.ct-builder-grid > .ct-grid-container > .ct-nft {
  width: 90%;
  max-width: 200px;
  margin: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.ct-builder-grid > .ct-grid-container > .ct-nft > .ct-image {
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ct-builder-grid > .ct-grid-container > .ct-nft > .ct-image > img {
  object-fit: contain;
  object-position: center;
  max-width: 90%;
}

.ct-builder-grid > .ct-grid-container > .ct-nft > .ct-data {
  padding: 15px;
  display: flex;
  flex-direction: column;
  font-size: 12px;
}

.ct-builder-grid > .ct-grid-container > .ct-pagination {
  flex: 0 0 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ct-builder-grid > .ct-grid-container > .ct-pagination > span {
  font-size: 18px;
  margin: 7px;
  padding: 5px;
  cursor: pointer;
}

.ct-builder-grid .ct-without-data a {
  text-decoration: none;
  font-size: 12px;
  max-width: 200px;
  display: flex;
  margin: 5px auto;
}

.ct-builder-grid .ct-without-data p {
  text-align: center;
  max-width: 90%;
  margin: 0 auto;
  display: block;
}

.ct-builder-grid .ct-without-data a > img {
  width: 20px;
  margin-left: 10px;
  display: block;
}

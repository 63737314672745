.ct-game-modal {
  background: rgba(34, 34, 34, 0.705);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ct-game-modal > .ct-data-container {
  background: white;
  padding: 15px;
  width: 90%;
  max-width: 350px;
}

.ct-game-modal > .ct-data-container > h1 {
  font-size: 25px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.ct-game-modal > .ct-data-container > h1 > span {
  font-size: 15px;
  cursor: pointer;
}

.ct-game-modal > .ct-data-container > h3 {
  font-size: 15px;
}

.ct-game-modal > .ct-data-container > p {
  margin-top: 15px;
  font-size: 12px;
}

.ct-game-modal > .ct-data-container > p > a {
  font-weight: bold;
  color: black;
}

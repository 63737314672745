@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Roboto Condensed", sans-serif;
}

body {
  max-width: 100vw;
  max-height: 100vh;
}

.ct-app-container {
  width: 100%;
  position: relative;
  margin-top: 60px;
}

.ct-max-container {
  max-width: 1200px;
  margin: 0 auto;
}

/* -------------------------------------------------------------------------- */
/*                               ANCHOR Buttons                               */
/* -------------------------------------------------------------------------- */

.ct-main-button {
  transition: all 0.2s ease;
  background: black;
  border: 0;
  color: white;
  height: 30px;
  padding: 0 15px;
  font-weight: bold;
  cursor: pointer;
  margin: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 98%;
  border-radius: 4px;
}

.ct-reaload-button {
  background: yellow;
  color: black;
  font-weight: bold;
  border: 0;
  display: flex;
  height: 30px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.ct-main-button:disabled {
  background: #ef9a9a;
}

.ct-template-button {
  background: black;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  text-transform: uppercase;
  font-size: 12px;
  border: 0;
  color: white;
  height: 40px;
  cursor: pointer;
}

.ct-main-button:disabled:hover {
  background: #ef9a9a;
}

.ct-main-button.ct-active {
  background: #7e7e7e;
}

.ct-main-button:hover {
  background: #7e7e7e;
}

/* -------------------------------------------------------------------------- */
/*                               ANCHOR Margins                               */
/* -------------------------------------------------------------------------- */

.ct-mb-1 {
  margin-bottom: 1px;
}

.ct-mb-2 {
  margin-bottom: 2px;
}

.ct-mb-5 {
  margin-bottom: 5px;
}

.ct-pt-30 {
  padding-top: 30px;
}

/* -------------------------------------------------------------------------- */
/*                               ANCHOR Comming                               */
/* -------------------------------------------------------------------------- */

.ct-comming-soon {
  display: flex;
  width: 100%;
  height: calc(100vh - 60px);
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.ct-comming-soon > button {
  background: black;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  text-transform: uppercase;
  font-size: 12px;
  border: 0;
  color: white;
  height: 40px;
  cursor: pointer;
}

.ct-comming-soon > h4 {
  font-size: 35px;
  text-transform: uppercase;
}

/* -------------------------------------------------------------------------- */
/*                              ANCHOR Calculator                             */
/* -------------------------------------------------------------------------- */

.ct-calculator-container {
  padding-top: 15px;
}

.ct-calculator-container > h1 {
  text-align: center;
  margin-bottom: 15px;
  text-transform: uppercase;
  font-size: 18px;
}

div.ct-shadow {
  background: rgba(248, 248, 248, 0.514);
  z-index: 99;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 32px;
}

div.ct-shadow.ct-active.page {
  display: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-variant: small-caps;
}

.ct-text-center {
  text-align: center;
}

.ct-text-light {
  font-weight: lighter;
}

.spinner {
  width: 40px;
  height: 40px;
  background-color: #333;
  margin: 100px auto;
  -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
  animation: sk-rotateplane 1.2s infinite ease-in-out;
}

@-webkit-keyframes sk-rotateplane {
  0% {
    -webkit-transform: perspective(120px);
  }
  50% {
    -webkit-transform: perspective(120px) rotateY(180deg);
  }
  100% {
    -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg);
  }
}

@keyframes sk-rotateplane {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }
  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }
  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}

.ct-relations-game > h1 {
  text-align: center;
  padding-top: 55px;
}

.ct-relations-game > p {
  text-align: center;
}

.ct-relations-without-factories {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 45px;
}

.ct-relations-without-factories > a {
  font-weight: bold;
  color: black;
  font-size: 16px;
}

.ct-relations-rewards {
  margin-top: 50px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.ct-relations-rewards > h1 small {
  font-size: 12px;
}

.ct-relations-rewards > h1 {
  display: flex;
  align-items: center;
}

.ct-relations-rewards > h1 > span {
  display: flex;
  flex-direction: column;
}

.ct-relations-rewards > button {
  max-width: 200px;
  margin-top: 20px;
}

.ct-select-employees {
  color: red;
  font-weight: bold;
  margin-top: 15px;
}

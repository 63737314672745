.ct-left-nav {
  box-shadow: 0 0 1px 0 rgb(8 11 14 / 20%), 0 16px 16px -1px rgb(8 11 14 / 50%);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  max-width: 250px;
  left: -270px;
  background: white;
  z-index: 1000;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: all 0.2s ease;
}

.ct-left-nav.ct-open {
  left: 0;
}

.ct-left-nav > .ct-link-parent {
  padding: 0 10px;
  margin-bottom: 15px;
}

.ct-left-nav > .ct-link-parent > span {
  font-size: 16px;
  color: grey;
  text-transform: uppercase;
  font-weight: lighter;
}

.ct-left-nav > .ct-link-parent > .ct-parent-links {
  padding-left: 10px;
  margin-top: 5px;
  display: flex;
  flex-direction: column;
}

.ct-left-nav > .ct-link-parent > .ct-parent-links > a {
  color: black;
  text-decoration: none;
  position: relative;
  padding: 5px;
  font-weight: lighter;
}

.ct-left-nav > .ct-link-parent > .ct-parent-links > a::before {
  content: "";
  position: absolute;
  bottom: 0;
  background: black;
  height: 1px;
  left: 0;
  width: 50%;
  transition: all 0.2s ease;
}

.ct-left-nav > .ct-link-parent > .ct-parent-links > a:hover::before {
  width: 100%;
}

.ct-left-nav > a {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: black;
  transition: all 0.2s ease;
}

.ct-left-nav > a:hover {
  box-shadow: 0 0 1px 0 rgb(8 11 14 / 6%), 0 16px 16px -1px rgb(8 11 14 / 10%);
}
